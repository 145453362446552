var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-0 p-0"},[_c('b-table',{staticClass:"text-center",attrs:{"per-page":8,"hover":"","current-page":_vm.page,"responsive":"","items":_vm.items,"fields":[
      {
        key: 'chargebackId',
        label: 'id'
      },
      {
        key: 'incomingDate',
        label: 'Data da Solicitação'
      },
      {
        key: 'transactionDate',
        label: 'Data da Transação'
      },
      {
        key: 'processNumber',
        label: 'Número do Processo'
      },
      {
        key: 'nsu',
        label: 'NSU'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'value',
        label: 'Valor'
      },
      {
        key: 'postedRate',
        label: 'Taxas'
      } ]},on:{"row-clicked":function (item) { return _vm.$emit('row-clicked', item.chargebackId); }},scopedSlots:_vm._u([{key:"cell(transactionDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.transactionDate))+" ")]}},{key:"cell(incomingDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.incomingDate))+" ")]}},{key:"cell(value)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.value))+" ")]}},{key:"cell(postedRate)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.postedRate ? 'Sim' : 'Não')+" ")]}}])}),_c('b-pagination',{staticClass:"my-2",attrs:{"total-rows":_vm.items.length,"per-page":8,"align":"center","size":"sm"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }