<template>
  <div>
    <b-row>
      <b-col
        md="4"
      >
        <h6> Status: </h6>
        <p> {{ item.status }} </p>
      </b-col>
      <b-col
        md="4"
      >
        <h6> Titular do Cartão: </h6>
        <p> {{ item.cardHolderName }} </p>
      </b-col>

      <b-col
        md="4"
      >
        <h6> Valor da Transação: </h6>
        <p> {{ item.transactionValue | value }} </p>
      </b-col>

      <b-col md="4">
        <h6> Data da Solicitação: </h6>
        <p> {{ item.incomingDate | date }} </p>
      </b-col>
      <b-col
        md="4"
      >
        <h6> Data da Transação: </h6>
        <p> {{ item.transactionDate | date }} </p>
      </b-col>

      <b-col
        md="4"
      >
        <h6> Número do Processo: </h6>
        <p> {{ item.processNumber }} </p>
      </b-col>

      <b-col
        md="4"
      >
        <h6> Bandeira: </h6>
        <p> {{ item.brandDescription }} </p>
      </b-col>

      <b-col
        md="4"
      >
        <h6> Razão: </h6>
        <p> {{ item.chargebackReason }} </p>
      </b-col>

      <b-col
        md="4"
      >
        <h6> Status do Último Documento: </h6>
        <p> {{ item.lastDocumentStatus }} </p>
      </b-col>

      <b-col
        md="12"
      >
        <h6> Solicitação de Evidência: </h6>
        <p> {{ item.evidenceRequest }} </p>
      </b-col>

      {{ item }}

      <b-col md="12">
        <h6> Documentos Disponíveis: </h6>
        <b-list-group>
          <b-list-group-item
            v-for="(file, index) in item.availableFiles"
            :key="index"
            @click="downloadFile(file.fileName)"
          >
            {{ file.fileName }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCol, BRow, BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import ChargebackService from '@/service/chargeback'

export default {
  components: {
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    async downloadFile(fileName) {
      const { data } = await ChargebackService.download({ fileName })

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
  },
}
</script>
