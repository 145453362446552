import methods from '@/enums/methods';
import Thoth from '../base/thoth';

class ChargebackService extends Thoth {
    chargeback({
        initialDate,
        finalDate,
    }) {
        return this.fetch({
            url: 'chargebackRede',
            params: {
                initialDate,
                finalDate,
            },
        });
    }

    history({
        chargebackId,
    }) {
        return this.fetch({
            url: 'chargebackHistory',
            params: {
                chargebackId,
            },
        });
    }

    download({
        fileName,
    }) {
        const request = this.api.get('downloadChargeback', {
            params: {
                fileName,
            },
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return this.fetch(request);
    }

    upload({
        file,
        chargebackId,
    }) {
        const data = new FormData();
        data.append('file', file);

        return this.fetch({
            url: 'uploadChargeback',
            method: methods.POST,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                chargebackId,
            },
            data,
            maxBodyLength: Infinity,
        });
    }

    availableFiles({ chargebackId }) {
        const request = this.api.get('availableFiles', {
            params: {
                chargebackId,
            },
        });
        return this.fetch(request);
    }
}

export default new ChargebackService();
