<template>
  <b-form>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Data Inicial"
          label-for="initial-date"
        >
          <b-form-input
            id="initial-date"
            v-model="request.initialDate"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Data Final"
          label-for="final-date"
        >
          <b-form-input
            id="final-date"
            v-model="request.finalDate"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-button
        variant="primary"
        class="d-block m-auto"
        @click="$emit('doRequest')"
      >
        Consultar
      </b-button>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BRow,
  BCol, BButton,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
  },

  props: {
    request: {
      type: Object,
      default: () => null,
    },
  },
}
</script>
