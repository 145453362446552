<template>
  <b-container>
    <b-card>
      <chargeback-form
        :request="request"
        @doRequest="chargebackRequest(request)"
      />
    </b-card>

    <b-card>
      <ChargebackTable
        :items="items"
        @row-clicked="handleRowClick"
      />
    </b-card>

    <b-modal
      id="chargeback-modal"
      hide-footer
      size="lg"
      :title="detailsItem.chargebackReason"
    >
      <chargeback-details
        :item="detailsItem"
      />
    </b-modal>
  </b-container>
</template>

<script>
import {
    BContainer,
    BCard,
    BModal,
} from 'bootstrap-vue';

import { DateTime } from 'luxon';

import ChargebackForm from './components/Form.vue';
import ChargebackTable from './components/Table.vue';
import ChargebackDetails from './components/Details.vue';

import ChargebackService from '@/service/chargeback/';

import { callLoading } from '@/service/utilities/index';

export default {
    components: {
        BModal,
        BCard,
        BContainer,
        ChargebackForm,
        ChargebackTable,
        ChargebackDetails,
    },

    data() {
        return {
            request: {
                finalDate: null,
                initialDate: null,
            },
            items: [],
            detailsItem: { },
        };
    },

    created() {
        const formatDate = date => date.toFormat('yyyy-MM-dd');

        const initialDate = formatDate(DateTime.now().minus({ days: 30 }));
        const finalDate = formatDate(DateTime.now());

        this.chargebackRequest({
            initialDate,
            finalDate,
        });
    },

    methods: {
        async chargebackRequest({ finalDate, initialDate }) {
            callLoading(true);
            const { data } = await ChargebackService.chargeback({ finalDate, initialDate });
            this.items = data;
            callLoading(false);
        },

        async handleRowClick(id) {
            this.$router.push(`/chargeback/${id}`);
        },

        async availableFiles({ chargebackId }) {
            const { data } = await ChargebackService.availableFiles({
                chargebackId,
            });

            return data;
        },
    },
};
</script>
