<template>
  <div class="m-0 p-0">
    <b-table
      class="text-center"
      :per-page="8"
      hover
      :current-page="page"
      responsive
      :items="items"
      :fields="[
        {
          key: 'chargebackId',
          label: 'id'
        },
        {
          key: 'incomingDate',
          label: 'Data da Solicitação'
        },
        {
          key: 'transactionDate',
          label: 'Data da Transação'
        },
        {
          key: 'processNumber',
          label: 'Número do Processo'
        },
        {
          key: 'nsu',
          label: 'NSU'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'value',
          label: 'Valor'
        },
        {
          key: 'postedRate',
          label: 'Taxas'
        },
      ]"
      @row-clicked="item => $emit('row-clicked', item.chargebackId)"
    >
      <template #cell(transactionDate)="data">
        {{ data.item.transactionDate | date }}
      </template>

      <template #cell(incomingDate)="data">
        {{ data.item.incomingDate | date }}
      </template>

      <template #cell(value)="data">
        {{ data.item.value | value }}
      </template>

      <template #cell(postedRate)="data">
        {{ data.item.postedRate ? 'Sim' : 'Não' }}
      </template>
    </b-table>

    <b-pagination
      v-model="page"
      :total-rows="items.length"
      :per-page="8"
      align="center"
      size="sm"
      class="my-2"
    />
  </div>
</template>

<script>
import {
    BTable,
    BPagination,
} from 'bootstrap-vue';

export default {
    components: {
        BTable,
        BPagination,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            page: 1,
        };
    },
};
</script>
